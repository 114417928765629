.opportunity-hub-horizontal-item-container {
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
}

.opportunity-hub-horizontal-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.opportunity-hub-horizontal-item-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.opportunity-hub-horizontal-item-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.opportunity-hub-horizontal-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.opportunity-hub-horizontal-item-label {
  margin-right: 10px;
}

.opportunity-hub-horizontal-item-label-status {
  color: #72CC00;
  font-family: 'Brink-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.opportunity-hub-horizontal-item-label-regime-container {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #E6E9F7;
  margin-right: 10px;
}

.opportunity-hub-horizontal-item-label-regime {
  color: #0227B0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.opportunity-hub-horizontal-item-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.opportunity-hub-horizontal-item-icon img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}