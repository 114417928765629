.nearshore-section02 {
    margin-bottom: 120px;
}

.nearshore-section02a {
    padding: 30px;
    display: flex;
    justify-content: center;
}

.nearshore-section02a-title {
    width: 60%;
    padding-right: 120px;
}

.nearshore-section03a {
    width: 40%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nearshore-section03a-text02 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #171717;
}

.nearshore-section-title-text {
    font-family: Brink-Regular, serif;
    margin-left: -20px;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #171717;
    margin-bottom: 10px;
    margin-top: 0;
}

.nearshore-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nearshore-three-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
}

.nearshore-single-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    min-width: 360px;
    max-width: 360px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);
}

.nearshore-single-block-large {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    min-width: 580px;
    max-width: 580px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);
}

.nearshore-single-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    min-width: 360px;
    max-width: 360px;
}

.nearshore-single-item-title {
    color: #011561;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
}

.nearshore-single-item-description {
    color: #171717;
    font-size: 20px;
    line-height: 32px;
}