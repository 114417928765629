.people-are-key-card {
    width: 300px;
    margin: 16px;
    overflow: hidden;
}

.people-are-key-card-image {
    width: 100%;
    height: auto;
}

.people-are-key-card-content {
    padding: 16px;
}

.people-are-key-card-title {
    color: #011561;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
}

.people-are-key-card-job-role {
    color: #171717;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
}