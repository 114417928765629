.section02a {
    padding: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section02b {
    padding: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section02b-img {
    position: relative;
    top: -35px;
}

.section02a-text01 {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #171717;
}

.section02a-h2 {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #01104A;
}

.section02b-h2 {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #01104A;
}

.section02a-text02 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #171717;
}

.section02b-text01 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #171717;
}

@media (max-width: 991px){
    .section02b {
        padding: 16px;
    }

    .section02a {
        padding: 16px;
    }

    .section02a-text01 {
        padding: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){

}