.horizontal-accordion {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    padding: 30px;
  }
  
  .horizontal-accordion.open {
    max-height: 200px; /* Adjust the desired maximum height */
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    /* background-color: #f0f0f0; */
    border-bottom: 1px solid #949494;
  }
  
  .title {
    flex-grow: 1;
    margin: 0;
    color: #0A0A0A;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 166.667% */
  }
  
  .toggle-arrow {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    mask-image: url('Chevron_Down.png'); 
    mask-size: cover;
    mask-repeat: no-repeat;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    align-self: center;
  }
  
  .toggle-arrow.open {
    transform: rotate(180deg);
  }
  
  .accordion-content {
    padding: 10px;
    color: #0A0A0A;

/* Text/Regular16 */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
  }
  