/* Header.css */
/* TODO: create media queries to adjust height for different resolutions */
.header {
    position: relative;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: #FAFAFA;
}

.header-no-video {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: #FAFAFA;
}

.logo {
    height: 30px;
}

.menu {
    position: absolute;
    right: 8%;
}

.menu-item {
    display: inline-block;
    margin-left: 20px;
}

.header-menu-row {
    position: relative;
    height: 80px;
    background: #FFFFFF25;
    display: flex;
    width: 100%;
    align-items: center;
    z-index: 1;
}

.header-block-text {
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 80px;
    justify-content: center;
}

.header-menu-item-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #FAFAFA;
    text-decoration: none;
    font-family: 'Brink-Thin';
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.header-top-text {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 30px;
    color: #7CEDFF;
}

.header-top-title {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 60px;
    width: 90%;
    color: #FAFAFA;
    margin: 0 0 100px 0;
}

.header-top-subtext {
    font-family: 'Brink-Regular', Arial, sans-serif;
    font-size: 20px;
    line-height: 40px;
    color: #62E9FF;
}

.logo-holder {
    width: 100vw;
    padding-left: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 910px) {
    .header-top-title {
        font-size: 30px;
        width: 100%;
    }

    .header-block-text {
        vertical-align: center;
        padding: 40px 16px;
    }

    .header-top-subtext {
        font-size: 14px;
    }

    .header-top-text {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .logo-holder {
        padding-left: 16px;
    }
}

.header-menu-item-selected {
    color: #3BE4FF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.menu-item-selected-bar {
    position: absolute;
    top: 8px;
    letter-spacing: 0px;
}