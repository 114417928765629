body {
    font-family: 'Brink-Regular', Arial, sans-serif;
    margin: 0;
    overflow-x: hidden;
}

h1 {
    font-family: 'Brink-Bold', Arial, sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.centering {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nimber-margin-bottom-52 {
    margin-bottom: 52px;
}

.nimber-margin-0 {
    margin: 0;
}

.nimber-width-50 {
    width: 50%;
}

.container {
    max-width: 100vw;
    padding: 80px;
}

@media only screen and (min-width: 1440px) {
    .container{
        max-width: 1440px;
    }
}

@media only screen and (max-width: 1440px) {
    img {
        max-width: 100vw;
    }

    .mobile-padding {
        padding: 0 16px;
    }
}

@media only screen and (max-width: 1000px) {
    .container {
        padding: 0;
    }
}


