.job-listing {
    padding: 2rem;
  }
  
  .job-title {
    font-size: 2rem; 
    margin-bottom: 1rem;
  }
  
  .intro-text, .call-to-action {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  
  h2, h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  
  