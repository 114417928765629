.section-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 64px;

    .section-title-text {
        font-family: 'Brink-Bold', Arial, sans-serif;
        margin-left: -10px;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #01104A;
    }
}

@media (max-width: 991px){
    .section-title .section-title-text {
        font-size: 40px;
    }

    .section-title {
        padding: 0 16px;
        margin-bottom: 24px;
    }
}