.schedule-wrapper {
    display: flex;
}

.contact-box {
    display: flex;
    width: 400px;
    background: #FAFAFA;
    padding: 26px 36px;
}

.contact-box img {
    margin-right: 32px;
}

.contact-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.call-schedule-text {
    color: #171717;
    font-size: 50px;
    font-weight: 700;
}