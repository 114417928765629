.footer-wrapper {
    padding: 80px;
    width: 100%;
    background: #171717;
}

.footer-top {
    width: 100%;
    border-bottom: 1px solid #646464;
}

.footer-top img {
    margin-bottom: 24px;
}

.footer-middle {
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 340px;
    color: #949494;
}

.footer-bottom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    color: #646464;
}

.footer-design {
    display: flex;
    gap: 33px;
}

.footer-special {
    color: #949494;
}


@media (max-width: 991px){
    .footer-middle {
        gap: 0;
        flex-direction: column;
    }

    .footer-bottom {
        gap: 16px;
        flex-direction: column;
    }

    .footer-wrapper {
        padding: 16px;
    }

}