.card {
    display: flex;
    width: 390px;
    height: 420px;
    padding: 48px 63px;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FAFAFA;
    box-shadow: 0px 0px 30px 10px rgba(148, 148, 148, 0.10);
}

@media (max-width: 1200px) {
    .card {
        max-width: 100vw;
    }
}