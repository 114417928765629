.job-application-form {
    max-width: 500px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
  }
  
  .status {
    color: #000;
    font-weight: bold;
  }
  
  .salary-range {
    color: #000;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .freelance {
    color: #666;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .file-upload-label {
    display: block;
    border: 2px dashed #ccc;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    margin: 10px 0;
  }
  
  .file-upload-label .browse {
    color: #0000ee;
    text-decoration: underline;
  }
  
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .apply-button {
    width: 100%;
    padding: 15px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .apply-button:hover {
    background-color: #333;
  }
  
  