.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.pagination-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #D6D6D6;
    margin: 0 5px;
    cursor: pointer;
}

.selected {
    width: 12px;
    height: 12px;
    background-color: #0227B0;
}