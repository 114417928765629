/* Brink - BR Shape Black Italic */
@font-face {
    font-family: 'Brink-Black-Italic';
    src: local('Brink-Black-Italic'), url('../fonts/brshape/Brink - BR Shape Black Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  
  /* Brink - BR Shape Black */
  @font-face {
    font-family: 'Brink-Black';
    src: local('Brink-Black'), url('../fonts/brshape/Brink - BR Shape Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Brink - BR Shape Bold Italic */
  @font-face {
    font-family: 'Brink-Bold-Italic';
    src: local('Brink-Bold-Italic'), url('../fonts/brshape/Brink - BR Shape Bold Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }
  
  /* Brink - BR Shape Bold */
  @font-face {
    font-family: 'Brink-Bold';
    src: local('Brink-Bold'), url('../fonts/brshape/Brink - BR Shape Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  /* Brink - BR Shape Super Italic */
  @font-face {
    font-family: 'Brink-Super-Italic';
    src: local('Brink-Super-Italic'), url('../fonts/brshape/Brink - BR Shape Super Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  
  /* Brink - BR Shape Super */
  @font-face {
    font-family: 'Brink-Super';
    src: local('Brink-Super'), url('../fonts/brshape/Brink - BR Shape Super.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Brink - BR Shape Thin Italic */
  @font-face {
    font-family: 'Brink-Thin-Italic';
    src: local('Brink-Thin-Italic'), url('../fonts/brshape/Brink - BR Shape Thin Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  
  /* Brink - BR Shape Thin */
  @font-face {
    font-family: 'Brink-Thin';
    src: local('Brink-Thin'), url('../fonts/brshape/Brink - BR Shape Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  /* Brink - BR Shape Regular Italic */
  @font-face {
    font-family: 'Brink-Regular-Italic';
    src: local('Brink-Regular-Italic'), url('../fonts/brshape/Brink - BR Shape Regular Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }

  /* Brink - BR Shape Regular */
  @font-face {
    font-family: 'Brink-Regular';
    src: local('Brink-Regular'), url('../fonts/brshape/Brink - BR Shape Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }