.tab {
    height: 100px;
    width: 300px;
    color: #949494;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tab:hover {
    color: #0227B0;
}

.active {
    color: #0227B0;
    border-top: 1px #D6D6D6 solid;
    border-bottom: 1px #D6D6D6 solid;
}

.slick-slider {
    margin: 0 !important;
    padding: 0 !important;
}

.slick-slide {
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: 1000px) {
    .tab span {
        width: 100%;
        text-align: center;
    }

    .tab {
        height: 74px;
        padding-bottom: 24px;
    }

    .active {
        border-top: none;
        border-bottom: 2px #0227B0 solid;
    }
}
