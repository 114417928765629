.sendUsYourCVForm-container {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    border-radius: 8px;
    padding-top: 50px;
    padding-left: 50px;
    padding-bottom: 25px;
    padding-right: 25px;
    width: 845px;
    height: auto;
    flex-shrink: 0;
}

.sendUsYourCVForm-inputs-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sendUsYourCVForm-inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sendUsYourCVForm-inputs input {
    width: 302px;
    padding: 0 0 0 16px;
    height: 48px;
    border: 1px solid #D6D6D6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #646464;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    margin-bottom: 40px;
}

.sendUsYourCVForm-inputs label {
    display: flex;
    align-items: flex-start;
}

.sendUsYourCVForm-row {
    margin-bottom: 16px;
}

.sendUsYourCVForm-title {
    color: #0D0D0D;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    margin-bottom: 16px;
}

.sendUsYourCVForm-description {
    width: 357px;
    color: #000;

    /* Text/Regular16 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    margin-bottom: 41px;
}

.sendUsYourCVForm-upload-file-section {
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    padding: 37px 28px;

    height: 114px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 6px;
    border: 1px dashed #A3A3A3;
    width: 620px;
}

.sendUsYourCVForm-upload-file-section-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sendUsYourCVForm-upload-file-section-description {
    color: #A3A3A3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.3px;
}

.sendUsYourCVForm-upload-file-section-description-mb {
    color: #646464;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
}

#txtName {
    margin-right: 16px;
}

.sendUsYourCVForm-attached-file-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 16px;
    align-items: flex-end;
    align-self: flex-end;
    gap: 16px;

}

.sendUsYourCVForm-attached-file {
    width: 620px;

    display: flex;
padding: 16px 40px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 6px;
background: #101010;

}

.sendUsYourCVForm-file {
    color: #FFF;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
letter-spacing: 0.3px;
}

.sendUsYourCVForm-file-link {
    text-decoration: underline;
}

.sendUsYourCVForm-file-clicable {
    cursor: pointer;
}

.sendUsYourCVForm-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px
}

.sendUsYourCVForm-button-container button {
border: 1px solid #3552C0;
background: #FAFAFA;
color: #3552C0;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 100% */
letter-spacing: 1px;
text-transform: uppercase;
}

.sendUsYourCVForm-browse-file {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

/* Mobile */
@media (max-width: 991px) {
    .sendUsYourCVForm-container {
        width: 100%; 
        padding: 24px; 
    }

    .sendUsYourCVForm-inputs-container {
        justify-content: stretch;
    }

    .sendUsYourCVForm-inputs {
        flex-direction: column; 
        width: 100%;
    }

    .sendUsYourCVForm-inputs input {
        width: 100%; 
        margin-bottom: 24px; 
    }

    .sendUsYourCVForm-description {
        width: 100%; 
    }

    .sendUsYourCVForm-upload-file-section {
        width: 100%; 
    }

    .sendUsYourCVForm-attached-file-container {
        justify-content: stretch;
        margin-top: 16px;
        align-items: stretch;
        align-self: stretch;
        gap: 16px;
    }

    .sendUsYourCVForm-attached-file {
        width: 100%; 
    }

    .sendUsYourCVForm-button-container {
        justify-content: center; 
        margin-top: 20px; 
    }
}

/* Tablet */
@media (min-width: 992px) and (max-width: 1200px) {
    .sendUsYourCVForm-inputs-container {
        justify-content: stretch;
    }

    .sendUsYourCVForm-inputs {
        flex-direction: column; 
        width: 100%;
    }

    .sendUsYourCVForm-inputs input {
        width: 100%; 
        margin-bottom: 24px; 
    }
}
