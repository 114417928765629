.clients-wrapper {
    width: 100%;
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.techedge-our-methodology-container {
    display: flex;
    flex-direction: row;
}

.techedge-our-methodology-title {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: flex-start;
}

.techedge-our-methodology-text {
    color: #171717;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width: 40%;
}

.techedge-our-methodology-detailed-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 64px;
    width: 100%;
}

.techedge-our-methodology-detailed-description p {
    color: #171717;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 80%;
}

.techedge-our-methodology-detailed-description p span {
    font-family: 'Brink-Bold';
    color: #171717;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.techedge-our-methodology-detailed-description-text-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.techedge-why-nimber-detailed-description {
    color: #171717;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.techedge-why-nimber-cards {
    display: flex;
    gap: 24px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
}

.techedge-schedule-call-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.techedge-schedule-call-image {
    background-color: white;
    padding-top: 216px;
    padding-bottom: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.techedge-schedule-call-image h3 {
    font-family: 'Brink-Bold';
    color: #01104A;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 69px;
}

.techedge-schedule-call-title-image {
    display: flex;
    flex-direction: row;
}

.techedge-schedule-call-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.techedge-schedule-call-name {
    color: #0227B0;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.techedge-schedule-call-title {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.techedge-schedule-call-form {
    padding-top: 216px;
    padding-bottom: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.techedge-schedule-call-form form h3 {
    color: #171717;
    font-family: 'Brink-Bold';
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.techedge-schedule-call-form-space-24 {
    margin-bottom: 24px;
}

.techedge-schedule-call-form-group {
    display: flex;
    flex-direction: column;
}

.techedge-schedule-call-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.techedge-schedule-call-form-group input {
    width: 100%;
    padding: 0 0 0 16px;
    height: 48px;
    border: 1px solid #D6D6D6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #646464;
}

.techedge-schedule-call-form-group button {
    display: flex;
    height: 51px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    background: #0227B0;

    color: #FAFAFA;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
}