.nimberverse-it-challenges-container {
    display: flex;
    flex-direction: row;
}

.nimberverse-it-challenges-title {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.nimberverse-it-challenges-text {
    color: #171717;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width: 40%;
}

.nimberverse-ceo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nimberverse-ceo-message-container {
    display: flex;
    flex-direction: row;
    margin-top: 197px;
}

.nimberverse-ceo-message-first-quote {
    color: #454545;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    
    margin-right: 16px;
}

.nimberverse-ceo-message-text {
    color: #454545;

    
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 859px;
}

.nimverser-ceo-image {
    display: flex;
    flex-direction: row;
    margin-top: 49px;
    margin-left: -50%;
    align-self: center;
}

.nimverser-ceo-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
}

.nimverser-ceo-name {
    color: #171717;

    
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    
}

.nimverser-ceo-title {
    color: #949494;

    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    
}

.nimberverse-our-name-container {
    display: flex;
    flex-direction: row;
    margin-top: 220px;
    justify-content: space-between;
}

.nimberverse-our-name-text {
    margin-top: 160px;
    margin-left: 109px;
    width: 519px;
    align-self: center;
    color: #171717;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.nimberverse-nimber-container {
    display: flex;
    flex-direction: column;
    background: #0227B0;
    margin-top: 100px;
}

.nimberverse-nimber-nimgame-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #011561;
    margin-top: 180px;
    width: 100%;
}

.nimberverse-nimber-nimgame-text span {
    font-family: 'Brink-Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.nimberverse-nimber-nimgame-text p {
    font-family: 'Brink-Thin';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    width: 330px;
    margin-left: 5%;
}

.nimberverse-nimber-nimgame-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 10%;
}

.nimberverse-nimber {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -20px;
}

.nimberverse-nimber-nim {
    font-family: 'Brink-Bold';
    color: #011561;
    font-size: 130px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 20px;
}

.nimberverse-nimber-plus {
    font-family: 'Brink-Bold';
    color: #011561;
    font-size: 130px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 20px;
}

.nimberverse-nimber-ber {
    font-family: 'Brink-Bold';
    color: #FFF;
    font-size: 130px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nimberverse-nimber-number-arrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10%;
}

.nimberverse-nimber-number-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #FFF;
    margin-bottom: 290px;
    width: 100%;
}

.nimberverse-nimber-number-text span {
    font-family: 'Brink-Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.nimberverse-nimber-number-text p {
    font-family: 'Brink-Thin';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    width: 330px;
}

.nimberverse-our-personality-container {
    margin-top: 100px;
}

.nimberverse-techfolio-container {
    display: flex;
    flex-direction: column;
}

.nimberverse-techfolio-title-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nimberverse-techfolio-image {
    width: 40%;
    margin-top: 90px;
}

.nimberverse-people-are-key {
    display: flex;
    flex-direction: column;
}

.nimberverse-people-are-key-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nimberverse-people-are-key-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #171717;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.nimberverse-people-are-key-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 74px;
}

.nimberverse-people-are-key-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 64px;
}

.nimberverse-our-brazilian-friends {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 225px;
}

.nimberverse-our-brazilian-friends-two-columns {
    display: flex;
    flex-direction: row;
}

.nimberverse-our-brazilian-friends-title-column {
    width: 448px;
    color: #FAFAFA;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nimberverse-our-brazilian-friends-description-column {
    width: 628px;
    color: #FAFAFA;
    font-family: 'Brink-Thin';
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    line-height: 35px;
    margin-left: 185px;
}

.nimberverse-our-brazilian-friends-verse-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 272px;
    margin-bottom: 215px;
    width: auto;
    align-items: center;
}

.nimberverse-our-brazilian-friends-verse-container p {
    color: #FAFAFA;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nimberverse-our-brazilian-friends-verse-alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 725px;
}

.footer-envelop {
    width: 100%;
    background: #171717;
    display: flex;
    justify-content: center;
}


@media (max-width: 991px) {
    .nimberverse-it-challenges-container {
        flex-direction: column;
    }

    .nimberverse-it-challenges-title {
        width: 100%; 
        text-align: center; 
    }

    .nimberverse-it-challenges-text {
        width: 100%; 
        text-align: center; 
        margin-top: 20px; 
    }

    .nimberverse-ceo-container {
        align-items: center; 
    }

    .nimberverse-ceo-message-container {
        flex-direction: row; 
        align-items: center; 
        
        flex-wrap: wrap;
    }

    .nimverser-ceo-image {
        margin-left: 0; 
        margin-bottom: 100px;
        justify-content: flex-start;
    }

    .nimverser-ceo-details-container {
        margin-left: 0; 
        text-align: center; 
        margin-top: 10px; 
    }

    .nimberverse-ceo-message-text {
        width: 100%;
    }

    .nimberverse-our-name-container {
        flex-direction: column; 
        align-items: center; 
        margin-top: 20px; 
    }

    .nimberverse-our-name-text {
        margin-left: 0; 
        text-align: center; 
        margin-top: 20px; 
        width: 100%;
    }

    .nimberverse-nimber-container {
        margin-top: 20px; 
    }

    .nimberverse-nimber-nimgame-text {
        justify-content: center; 
        margin-top: 20px; 
    }

    .nimberverse-nimber-nimgame-arrow {
        margin-left: 0; 
        margin-top: 10px; 
    }

    .nimberverse-nimber {
        justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    }

    .nimberverse-nimber-ber {
        color: #011561; 
    }

    .nimberverse-nimber-number-arrow {
        margin-right: 0; 
        margin-top: 10px; 
    }

    .nimberverse-nimber-number-text {
        justify-content: center; 
        margin-top: 10px; 
    }

    .nimberverse-nimber-nimgame-text p {
        width: 70%;
    }

    .nimberverse-nimber-number-text p {
        width: 70%;
    }

    .nimberverse-our-personality-container,
    .nimberverse-techfolio-container,
    .nimberverse-people-are-key,
    .nimberverse-our-brazilian-friends {
        margin-top: 20px; 
    }

    .nimberverse-techfolio-image {
        display: none;
    }

    .nimberverse-people-are-key-description {
        text-align: center;
    }

    .nimberverse-people-are-key-button-container {
        margin-top: 20px; 
    }

    .people-are-key-card-container {
        flex-direction: column;
    }

    .nimberverse-our-brazilian-friends-two-columns {
        flex-direction: column; 
    }

    .nimberverse-our-brazilian-friends-title-column,
    .nimberverse-our-brazilian-friends-description-column {
        width: 100%; 
        text-align: center; 
        margin-left: 0; 
    }

    .nimberverse-our-brazilian-friends-verse-container {
        margin-top: 20px; 
    }

    .nimberverse-our-brazilian-friends-verse-alignment {
        width: 100%;
    }

    .nimberverse-our-brazilian-friends-verse-container p {
        font-size: 20px; 
        width: 100%;
        text-wrap: wrap;
        flex-wrap: wrap;
    }

    .footer-envelop {
        padding-top: 20px; 
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .nimberverse-it-challenges-container {
        flex-direction: column;
    }

    .nimberverse-it-challenges-title {
        width: 100%; 
        text-align: center; 
    }

    .nimberverse-it-challenges-text {
        width: 100%; 
        text-align: center; 
        margin-top: 20px; 
    }

    .nimberverse-ceo-container {
        align-items: center; 
    }

    .nimberverse-ceo-message-container {
        flex-direction: row; 
        align-items: center; 
        
        flex-wrap: wrap;
    }

    .nimverser-ceo-image {
        margin-left: 0; 
        margin-bottom: 100px;
        justify-content: flex-start;
    }

    .nimverser-ceo-details-container {
        margin-left: 0; 
        text-align: center; 
        margin-top: 10px; 
    }

    .nimberverse-ceo-message-text {
        width: 100%;
    }

    .nimberverse-our-name-container {
        flex-direction: column; 
        align-items: center; 
        margin-top: 20px; 
    }

    .nimberverse-our-name-text {
        margin-left: 0; 
        text-align: center; 
        margin-top: 20px; 
        width: 100%;
    }

    .nimberverse-nimber-container {
        margin-top: 20px; 
    }

    .nimberverse-nimber-nimgame-text {
        justify-content: center; 
        margin-top: 20px; 
    }

    .nimberverse-nimber-nimgame-arrow {
        margin-left: 0; 
        margin-top: 10px; 
    }

    .nimberverse-nimber {
        justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    }

    .nimberverse-nimber-ber {
        color: #011561; 
    }

    .nimberverse-nimber-number-arrow {
        margin-right: 0; 
        margin-top: 10px; 
    }

    .nimberverse-nimber-number-text {
        justify-content: center; 
        margin-top: 10px; 
    }

    .nimberverse-nimber-nimgame-text p {
        width: 70%;
    }

    .nimberverse-nimber-number-text p {
        width: 70%;
    }

    .nimberverse-our-personality-container,
    .nimberverse-techfolio-container,
    .nimberverse-people-are-key,
    .nimberverse-our-brazilian-friends {
        margin-top: 20px; 
    }

    .nimberverse-techfolio-image {
        display: none;
    }

    .nimberverse-people-are-key-description {
        text-align: center;
    }

    .nimberverse-people-are-key-button-container {
        margin-top: 20px; 
    }

    .people-are-key-card-container {
        flex-direction: column;
    }

    .nimberverse-our-brazilian-friends-two-columns {
        flex-direction: column; 
    }

    .nimberverse-our-brazilian-friends-title-column,
    .nimberverse-our-brazilian-friends-description-column {
        width: 100%; 
        text-align: center; 
        margin-left: 0; 
    }

    .nimberverse-our-brazilian-friends-verse-container {
        margin-top: 20px; 
    }

    .nimberverse-our-brazilian-friends-verse-alignment {
        width: 100%;
    }

    .nimberverse-our-brazilian-friends-verse-container p {
        font-size: 20px; 
        width: 100%;
        text-wrap: wrap;
        flex-wrap: wrap;
    }

    .footer-envelop {
        padding-top: 20px; 
    }
}
