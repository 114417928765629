.opportunity-hub-working-at-nimber-container {
    display: flex;
    flex-direction: row;
}

.opportunity-hub-working-at-nimber-title {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.opportunity-hub-working-at-nimber-text {
    color: #171717;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width: 40%;
}

.opportunity-hub-cant-find-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.opportunity-hub-cant-find-container h1 {
    color: #171717;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 28px;
}

.opportunity-hub-cant-find-container p {
    color: #171717;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 82px;
}

.opportunity-hub-cant-find-container button {
    display: inline-flex;
    height: 56px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #FAFAFA;
    background: #0227B0;
    color: #FAFAFA;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}