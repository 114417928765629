.digital-traces-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 130px;
}

.nimber-case-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 130px;
}

.nimber-case-techs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 36px;
}

.nimber-sub-section-titles {
    font-family: Brink-Regular, serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: #171717;
    padding-bottom: 24px;
}

.nimber-texts {
    font-family: Brink-Regular, serif;
    font-size: 16px;
    line-height: 24px;
    color: #171717;
    padding-bottom: 32px;
}

.nimber-main-title-digital {
    font-family: Brink-Regular, serif;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 24px;
    color: #101010;
}

@media (max-width: 991px){
    .digital-traces-wrapper {
        flex-direction: column;
        gap: 80px;
    }
}
