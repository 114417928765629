.title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-envelop {
    width: 100%;
    background: #171717;
    display: flex;
    justify-content: center;
}

.clients-wrapper {
    width: 100%;
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
}


@media (max-width: 991px){
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }
}
