.nimber-talent-block {
    display: flex;
    padding: 64px;
    gap: 20px;
    justify-content: center;
}

.nimber-talent-left {
    width: 50%;
}

.slick-slide {
    cursor: grab;
}


@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-talent-block {
        flex-direction: column;
    }

    .nimber-talent-left {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .nimber-talent-block {
        flex-direction: column;
        padding: 20px;
    }

    .nimber-talent-img {
        display: none;
    }

    .nimber-talent-left {
        width: 100%;
    }
}

.nimber-slider-max {
    max-width: 1440px;
}

.nimber-main-title {
    font-family: 'Brink-Regular', serif;
    font-weight: 700;
    font-size: 60px;
    color: #171717;
}

.nimber-secondary-text {
    font-family: 'Brink-Regular', serif;
    font-size: 20px;
    color: #171717;
    line-height: 32px;
}