.back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem; 
  }
  
  .back-arrow {
    margin-right: 8px;
  }
  