.header-button {
    display: flex;
    width: 270px;
height: 56px;
padding: 20px;
justify-content: center;
align-items: center;
gap: 20px;
border-radius: 6px;
border: 1px solid #3552C0;
background: #FAFAFA;
color: #3552C0;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 100% */
letter-spacing: 1px;
text-transform: uppercase;
}