.opportunity-hub-component {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 100;
    flex-shrink: 0;
    background: #FAFAFA;
}

.opportunity-hub-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.opportunity-hub-search-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.opportunity-hub-search-input {
    display: flex;
    width: 460px;
    height: 56px;
    flex-direction: column;
    font-size: 24px;
    padding-left: 15px;
    padding-right: 15px;
}

.opportunity-hub-button-filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px;
    gap: 8px;
}

.opportunity-hub-btn-filter {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    height: 48px;
}

.opportunity-hub-btn-filter-unselected {
    border: 1px solid #D6D6D6;
    background: #FAFAFA;
    color: #0A0A0A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 0.3px;
}

.opportunity-hub-btn-filter-selected {
    background:  #0227B0;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.opportunity-hub-or-search-for {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 285.714% */
}

.opportunity-hub-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100vw;
}

@media (max-width: 991px) {
    .opportunity-hub-search-section {
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }

    .opportunity-hub-search-input {
        display: flex;
        width: 100%;
        height: 56px;
        flex-direction: column;
        font-size: 24px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .icon-button {
        justify-content: center;
    }
}